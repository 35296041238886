import React, { Component } from 'react'
import styled from 'styled-components'
import Termin from './Termin'

const Container = styled.div`
  /* display: flex; */
  /* width: 100%; */
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 90px;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
`

const Header = styled.div`
  display: flex;
  width: 100%;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  color: ${p => p.theme.textColorDark};
  @media (max-width: 500px) {
    display: none;
  }
`

const First = styled.div`
  width: 20%;
`

const Second = styled.div`
  width: 40%;
`

const Third = styled.div`
  width: 40%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

class TermineContainer extends Component {
  render() {
    const { termine } = this.props
    return (
      <Container>
        {termine ? (
          <React.Fragment>
            <Header>
              <First>DATUM</First>
              <Second>VERANSTALTUNG</Second>
              <Third>ORT</Third>
            </Header>
            <Content>
              {termine &&
                termine.map(({ date, eventName, link, location, address }) => {
                  return (
                    <Termin
                      key={eventName}
                      date={date}
                      eventName={eventName}
                      link={link}
                      location={location}
                      address={address}
                    />
                  )
                })}
            </Content>
          </React.Fragment>
        ) : (
          <div>Derzeit gibt es keine aktuellen Termine. Schauen Sie bald wieder vorbei!</div>
        )}
      </Container>
    )
  }
}

export default TermineContainer

{
  /* <Termin
  date="21. - 23. Februar 2018"
  eventName="Weinmesse Innsbruck"
  link="https://www.cmw.at/de/messekalender/19_internationale_weinmesse_innsbruck_2019/home/"
  location="Messe Innsbruck, Eingang Ost | Halle A | Messeforum 2. OG"
  address="Siebererstraße, Ecke Claudiastraße, 6020 Innsbruck"
/>
<Termin
  date="04. - 07. April 2019"
  eventName="SCHAU! Die Vorarlberger Frühlingsausstellung"
  link="https://schau.messedornbirn.at/"
  location="Messe Dornbirn"
  address="Messeplatz 1, 6854 Dornbirn"
/>
<Termin
  date="12. – 14. April 2019"
  eventName="Golser Weinfrühling"
  link="https://weinort-gols.at/weinfruehling.html"
  location="Weingut Reinhard & Edith Göschl"
  address="Am Kanal 4, 7122 Gols"
/> */
}
