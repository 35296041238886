import React, { Component } from 'react'
import { graphql } from 'gatsby'

import HeadingMain from '../components/HeadingMain'
import TermineContainer from '../components/TermineContainer'
import SEO from '../components/SEO'

class Termine extends Component {
  render() {
    const { termine } = this.props.data.markdownRemark.frontmatter
    return (
      <React.Fragment>
        <SEO title="Termine" description="Verkosten Sie die Weine auf diversen Veranstaltungen." />
        <HeadingMain
          mainHeading="Aktuelle Termine"
          subHeading="Besuchen Sie uns doch bei einer der folgenden Veranstaltungen"
        />
        <TermineContainer termine={termine} />
      </React.Fragment>
    )
  }
}

export default Termine

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/termine/" }) {
      frontmatter {
        termine {
          date
          eventName
          link
          location
          address
        }
      }
    }
  }
`
