import React, { Component } from 'react'
import styled from 'styled-components'
import StyledLink from './styles/StyledLink'

const First = styled.div`
  width: 20%;
  padding-right: 20px;
  @media (max-width: 500px) {
    width: 100%;
    padding-right: 0;
  }
`

const Second = styled.div`
  width: 40%;
  padding-right: 20px;
  /* font-weight: 600; */
  @media (max-width: 500px) {
    width: 100%;
    margin-top: 22px;
    padding-right: 0;
    font-weight: 600;
  }
`

const Third = styled.div`
  width: 40%;
  @media (max-width: 500px) {
    width: 100%;
    margin-top: 22px;
  }
`

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${p => p.theme.lightGray};
  @media (max-width: 500px) {
    flex-wrap: wrap;
    margin-top: 22px;
    padding-top: 22px;
  }
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`

class Termin extends Component {
  render() {
    const { date, eventName, link, location, address } = this.props
    return (
      <Container>
        <First>{date}</First>
        <Second>
          {link ? (
            <StyledLink as="a" href={link} target="_blank" rel="noopener noreferrer">
              {eventName}
            </StyledLink>
          ) : (
            <div>{eventName}</div>
          )}
        </Second>
        <Third>
          <div>{location}</div>
          <div>{address}</div>
        </Third>
      </Container>
    )
  }
}

export default Termin
